import * as React from "react";
import { Page, Card, Button } from "@shopify/polaris";
import { TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { useCallback } from "react";
import { Redirect, Loading, Error, Toast } from "@shopify/app-bridge/actions";
import useQueryParam from "../../lib/useQueryParam";

const IndexPage = () => {
  const pageTitle = "Create shipping label";
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  const [id, setId] = useQueryParam("id", null);

  const redirectToDraft = useCallback(() => {
    // const url = `https://${shop}/draft_orders/${id}`;
    redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      name: Redirect.ResourceType.Order,
      resource: {
        id,
      },
    });
  }, [id]);
  return (
    <Page
      title={pageTitle}
      breadcrumbs={[{ content: "Draft", onAction: redirectToDraft }]}
    >
      <TitleBar title={pageTitle} />
      <Card
        sectioned
        secondaryFooterActions={[{ content: "Cancel", destructive: false }]}
        primaryFooterAction={{ content: "Create Draft" }}
      >
        <p>Create new draft</p>
      </Card>
      <main>
        <title>Home Page</title>
        <h1> Congratulations </h1>
        <p>
          This doesn't do a thing
          <span role="img" aria-label="Sunglasses smiley emoji">
            😎
          </span>
        </p>
      </main>
    </Page>
  );
};

export default IndexPage;
